(function ($) {
  jQuery(document).ready(function () {
    $(".filter-select-archive").select2({
      placeholder: "Filter",
      templateResult: formatState,
      //templateSelection: formatState,
    });
    function formatState(opt) {
      if (!opt.id) {
        return opt.text.toUpperCase();
      }

      var optimage = $(opt.element).attr("data-image");
      if (!optimage) {
        return opt.text.toUpperCase();
      } else {
        var $opt = $(
          '<div style="display:flex;justify-content: space-between;align-items:center;"><div>' +
            opt.text.toUpperCase() +
            '</div><img src="' +
            optimage +
            '" width="35px" height="20px" /> ' +
            "</div>"
        );
        return $opt;
      }
    }
  });
})(jQuery);
