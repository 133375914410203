(function ($) {
  jQuery(document).ready(function () {
    $("button.subscribe").click(function () {
      var email_input = $(this).prev();
      var data_post = {};
      var flag = true;
      $(email_input).removeClass("error");
      var value = "";
      if ($(email_input).attr("type") == "email") {
        var validRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if ($(email_input).val().match(validRegex)) {
          value = $(email_input).val();
        }
      } else {
        value = $(email_input).val();
      }
      if (value == "") {
        flag = false;
        $(email_input).addClass("error");
      }
      if (!flag) return;
      data_post["email"] = value;
      ThemeJS.Functions.send_ajax_request({
        data: {
          action: "new_subscribe_ajax_action",
          ...data_post,
        },
        success: (response) => {
          if (response) {
            $($(email_input).parent()).html(
              "<div><p>Thanks for Subscribe to our Newsletter</p></div>"
            );
          }
        },
      });
    });
  });
})(jQuery);
