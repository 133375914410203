(function ($) {
  jQuery(document).ready(function () {
    jQuery(".cards-section-slider").each((i, item) => {
      $(jQuery(item).find(".cards-container")).slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        prevArrow: jQuery(item).find(".card-prev"),
        nextArrow: jQuery(item).find(".card-next"),
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  });
})(jQuery);
