(function ($) {
  jQuery(document).ready(function () {
    if ($(".filter-button")) {
      $(".filter-button").on("click", function () {
        if ($(".filter-group").hasClass("hide-only-mobile")) {
          $(".filter-button").addClass("active");
          $(".filter-group").removeClass("hide-only-mobile");
        } else {
          $(".filter-button").removeClass("active");
          $(".filter-group").addClass("hide-only-mobile");
        }
      });
    }
  });
})(jQuery);
